<template>
<div class="payment-method">
  <div class="info-section hidden">
    <h5>{{ $t('checkout.payment.plg_wallet') }}</h5>
    <PLGWallet
      :walletBalance="walletBalance"
      @input="$emit('update:fromWallet', amount)"
    />
  </div>
  <div class="info-section">
    <div class="card-title">
      <h5>{{ $t('checkout.payment.credit_debit_card') }}</h5>
      <Checkbox
        class="save-card"
        :item="{ label: $t('checkout.payment.save_credit_card'), checked: saveForLater }"
        :onCheck="toggleSaveCreditCard"
      />
    </div>
    <TitledSelect
      v-if="paymentMethodsInProfile.length"
      v-model="selectedCard"
      class="card-select"
      :options="cardOptions"
      label="abbreviation"
      :placeholder="$t('checkout.payment.credit_debit_card')"
      @input="onSelectCard"
    >
      <template #option="{ option }">
        {{ option.abbreviation }}
        <div class="billing">
          {{ option.billingAbbreviation }}
        </div>
      </template>
    </TitledSelect>
    <CreditCardForm
      ref="cardForm"
      v-model="card"
      :hasCVV="true"
      @validate="creditCardValid = $event"
    />
  </div>
  <div class="info-section">
    <h5>{{ $t('checkout.payment.billing_address') }}</h5>
    <Checkbox
      v-if="shippingAddress"
      :item="{ label: $t('checkout.payment.use_shipping'), checked: useShippingAddress }"
      :onCheck="toggleUseShipping"
      class="use-shipping"
    />
    <AddressForm
      ref="addressForm"
      v-model="card.billing_address"
      formName="address"
      @validate="addressValid = $event"
    />
  </div>
  <TitledInput
    v-model="comment"
    :title="$t('checkout.review_confirm.comment')"
    :placeholder="$t('checkout.review_confirm.comment_placeholder')"
    :suffix="comment ? `${comment.length}/256` : '256'"
    :maxLength="256"
    :rows="3"
    class="checkout-comment"
  />
  <div v-if="checkoutError" class="checkout-error">
    {{ checkoutError }}
  </div>
  <PButton
    class="btn-submit"
    :animate="pledging"
    :disabled="disableContinue"
    @click="onContinue"
  >
    {{ $t('checkout.buttons.place_order') }}
  </PButton>
</div>
</template>

<script>
import moment from 'moment';
import CreditCardForm from '@/components/forms/CreditCardForm.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import Checkbox from '@/components/widget/Checkbox.vue';
import TitledSelect from '@/components/widget/TitledSelect.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import PButton from '@/components/widget/PButton';
import PLGWallet from '@/components/pledge/PLGWallet';

import { TOGGLE_SAVE_CREDIT_CARD, CLEAR_REFERRAL, USER_REQUEST } from '@/store/actions';
import { createPaymentMethod, updatePledgePayment, pledgeCheckout } from '@/utils/api';
import { firstErrorKey } from '@/utils/apiError';
import { deepCopy, formatCardAPI } from '@/utils/objectUtils';

const defaultCard = {
  billing_address: {
    country_code: 'USA',
  },
};

export default {
  name: 'payment-method',
  components: {
    PLGWallet,
    CreditCardForm,
    AddressForm,
    Checkbox,
    PButton,
    TitledSelect,
    TitledInput,
  },
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    walletBalance: {
      type: Number,
      default: 0,
    },
    shippingAddress: {
      type: Object,
      default: () => {},
    },
    fromWallet: {
      type: Number,
      default: 0,
    },
    project: {
      type: Object,
      default: () => {},
    },
    disableContinue: Boolean,
  },
  data() {
    return {
      card: { ...defaultCard },
      selectedCard: null,
      cardOptions: [],
      addressValid: false,
      creditCardValid: false,
      useShippingAddress: false,
      comment: '',
      pledging: false,
      checkoutError: null,
    };
  },
  computed: {
    isProfileLoaded() {
      return this.$store.getters.isProfileLoaded;
    },
    shoppingCart() {
      return this.$store.getters.shoppingCart(this.project.id);
    },
    totalPledgesValue() {
      return this.$store.getters.totalPledgesValue(this.project.id);
    },
    saveForLater() {
      return this.$store.getters.saveCreditCardStatus;
    },
    paymentMethodsInProfile() {
      const { isProfileLoaded, profile } = this.$store.getters;
      if(isProfileLoaded) {
        return profile.payment_methods.filter(({ expiration }) => moment(expiration).isBefore(moment()));
      }
      return [];
    },
    isNewCard() {
      return this.selectedCard && !this.selectedCard.id;
    },
    defaultPaymentMethod() {
      if(this.$store.getters.isProfileLoaded) {
        const methods = this.$store.getters.profile.payment_methods || [];
        if(methods.length === 0) {
          return null;
        }
        // Default payment method selection logic
        // 1. Find a default payment method.
        // 2. If not found, find a verified one.
        // 3. If not found again, return first available method.
        return methods.find(paymentMethod => paymentMethod.default)
          || methods.find(paymentMethod => paymentMethod.verified)
          || methods[0]
          || null;
      }
      return null;
    },
    canProceed() {
      return (!this.shippingAddress || this.addressValid) && this.creditCardValid;
    },
  },
  methods: {
    toggleSaveCreditCard() {
      this.$store.dispatch(TOGGLE_SAVE_CREDIT_CARD);
    },
    toggleUseShipping(value) {
      this.useShippingAddress = value;
      if(value) {
        this.card.billing_address = deepCopy(this.shippingAddress);
        this.$nextTick(() => this.$refs.addressForm.validateAll());
      }
    },
    onSelectCard(card) {
      this.card = deepCopy(this.formatCardDisplay(card));
      this.validateForm();
    },
    async onContinue(e) {
      this.checkoutError = null;
      if(this.totalPledgesValue > 100000) {
        this.checkoutError = this.$t('checkout.error_amount');
        return;
      }
      e.preventDefault();
      const addressValid = this.$refs.addressForm.validateAll();
      const cardValid = this.$refs.cardForm.validateAll();

      if(!(addressValid && cardValid)) {
        return;
      }
      this.pledging = true;
      try {
        // Add a new payment method
        const card = formatCardAPI(this.card, this.saveForLater && this.isNewCard);
        const { data: { payment_method: payment } } = await createPaymentMethod(card);
        await updatePledgePayment({
          projectId: this.project.id,
          pledgeId: this.shoppingCart.id,
          paymentId: payment.id,
        });

        await this.placeOrder(this.card);
      } catch(err) {
        this.checkoutError = this.$t(firstErrorKey(err.errors));
      }
      this.pledging = false;
    },
    async placeOrder(card) {
      const projectId = this.project.id;
      const { referral } = this.$store.getters;
      const pledgeData = {
        ...referral,
        cvv: card.cvv,
        comment: this.comment,
      };
      await pledgeCheckout(projectId, pledgeData);
      this.$store.dispatch(CLEAR_REFERRAL);
      this.$store.dispatch(USER_REQUEST);
      this.onSubmit();
    },
    addAbbreviation(o) {
      const { billing_address: address, last4 } = o;
      let abbreviation = 'Unknown card';
      let billingAbbreviation = '';

      if(last4 && address) {
        billingAbbreviation = (address.line1 && address.postal_code)
          ? `${address.line1}, ${address.postal_code}`
          : (address.line1 || address.postal_code || '');
        abbreviation = this.$t('checkout.payment.card_ending_in', { last4 });
      } else if(last4) {
        abbreviation = this.$t('checkout.payment.card_ending_in', { last4 });
      } else if(address) {
        abbreviation = `${address.line1}, ${address.postal_code}`;
      }
      return { ...o, abbreviation, billingAbbreviation };
    },
    formatCardDisplay(card) {
      return { ...formatCardAPI(card), cvv: '' };
    },
    validateForm() {
      this.$nextTick(() => {
        this.$refs.addressForm.validateAll();
        this.$refs.cardForm.validateAll();
      });
    },
  },
  mounted() {
    if(this.isProfileLoaded) {

      this.cardOptions = this.paymentMethodsInProfile.map(this.addAbbreviation);
      this.cardOptions.push({ abbreviation: this.$t('checkout.payment.add_card') });

      const savedPayment = this.defaultPaymentMethod;
      const payment = savedPayment || { ...defaultCard };
      this.selectedCard = this.addAbbreviation(payment);
      this.card = deepCopy(this.formatCardDisplay(payment));
      if(savedPayment) {
        this.validateForm();
      }
    }
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.payment-method {
  .info-section {
    .card-title {
      display: flex;
    }
    .save-card {
      margin-left: auto;
    }

    .card-select {
      max-width: 400px;
      margin-bottom: 32px;
    }
    &.hidden {
      display: none;
    }
    .use-shipping {
      margin: 4px 0 16px;
    }
  }
  .expire-row2 {
    display: flex;
    margin-top: -16px;
    .card-expire {
      margin-right: 12px;
      width: 190px;
    }
    .card-cvv {
      width: 80px;
    }
  }
  .checkout-comment textarea {
    resize: vertical;
  }
}
.card {
  display: flex;
  width: 100%;
  border: 1px solid $border-light;
  padding: 16px;

  h5 { margin-top: 32px; }
}

.billing {
  @include h5;
  color: $grey;
}
.checkout-error {
  @include error;
  justify-content: center;
}
</style>
