<template>
<Modal
  class="checkout-modal"
  :cancelable="false"
  :submitable="false"
  :expanded="true"
>
  <template #expandedHeader>
    <div class="expanded-header container">
      <div class="btn-back" @click="goBack">
        <Arrow :back="true" />
        <span class="button_small_text">
          {{ $t('back') }}
        </span>
      </div>
      <Logo :showLabel="false" />
      <div />
    </div>
  </template>
  <template #content>
    <div v-if="project && shoppingCart && !showSucess" class="checkout container">
      <div class="checkout-form">
        <h2>{{ $t('checkout.title') }}</h2>
        <Stepper
          v-if="steps.length > 1"
          :steps="stepTitles"
          :currentStep="currentStepIdx"
          :onStep="onStep"
        />
        <keep-alive>
          <component
            :is="currentStep.component"
            :onSubmit="onSubmit"
            :goTo="goTo"
            :shippingAddress="shippingAddress"
            :paymentMethod="paymentMethod"
            :project="project"
            :shippingCost="shippingCost"
            :walletBalance="walletBalance"
            :fromWallet="fromWallet"
            :disableContinue="disableContinue"
            @update:fromWallet="fromWallet = $event"
            @country-code="countryCode = $event"
          />
        </keep-alive>
      </div>
      <div class="checkout-right">
        <div class="checkout-note">
          <div class="checkout-note-title">
            {{ $t('checkout.note_title') }}
          </div>
          <div v-if="project.crowdfunding">
            {{ $t('checkout.note_text_crowdfunding') }}
          </div>
          <div v-else>
            {{ $t('checkout.note_text') }}
          </div>
        </div>
        <div class="checkout-items">
          <h3>{{ $t(`${rewardsKey}.title`) }}</h3>
          <PledgeItems
            :project="project"
            :fromWallet="fromWallet"
            :showCoupon="project.has_coupons"
          />
        </div>
        <div class="safe-checkout">
          <img class="safe-ssl" :src="require('@/static/img/ssl.png')">
          <div class="safe-wrap">
            <div class="safe-text-wrap">
              <div class="safe-line" />
              <img class="safe-lock" :src="require('@/static/img/icons/ic_lock.png')">
              <div class="safe-text">
                {{ $t('checkout.safe') }}
              </div>
              <div class="safe-line" />
            </div>
            <img class="safe-cards" :src="require('@/static/img/credit_cards.png')">
          </div>
        </div>
      </div>
    </div>
    <CheckoutSuccess
      v-if="showSucess"
      @done="gotoProject"
    />
  </template>
</Modal>
</template>

<script>
import Stepper from '@/components/pledge/Stepper.vue';
import PledgeItems from '@/components/pledge/PledgeItems.vue';
import ShippingInfo from '@/components/pledge/ShippingInfo.vue';
import PaymentMethod from '@/components/pledge/PaymentMethod.vue';
import CheckoutSuccess from '@/components/pledge/CheckoutSuccess.vue';
import Logo from '@/components/nav/Logo.vue';
import Arrow from '@/components/svg/Arrow.vue';
import Modal from '@/components/widget/Modal.vue';
import { cartShippingCost } from '@/utils/objectUtils';
import { getProjectDetail } from '@/utils/api';
import { GET_CART } from '@/store/actions';

export default {
  name: 'checkout',
  components: {
    Arrow,
    Logo,
    Stepper,
    PledgeItems,
    ShippingInfo,
    PaymentMethod,
    CheckoutSuccess,
    Modal,
  },
  data() {
    return {
      currentStepIdx: 0,
      shippingAddress: null,
      paymentMethod: null,
      // Store country early for shipping price calculation
      countryCode: null,
      project: null,
      walletBalance: 0,
      fromWallet: 0,
      showSucess: false,
    };
  },
  computed: {
    needsShipping() {
      return this.shoppingCart.needsShipping;
    },
    shippingCost() {
      return cartShippingCost(this.shoppingCart, this.countryCode || '*');
    },
    shoppingCart() {
      return this.$store.getters.shoppingCart(this.project.id);
    },
    disableContinue() {
      return Object.keys(this.shoppingCart.products || {}).length === 0;
    },
    steps() {
      if(!this.shoppingCart) {
        return [];
      }
      const result = [
        { title: this.$t('checkout.payment.title'), component: 'PaymentMethod' },
      ];
      if(this.needsShipping) {
        result.unshift({ title: this.$t('checkout.address.title'), component: 'ShippingInfo' });
      }
      return result;
    },
    stepTypes() {
      if(this.needsShipping) {
        return { SHIPPING: 0, PAYMENT: 1 };
      }
      return { PAYMENT: 0 };
    },
    stepTitles() {
      return this.steps.map(step => (step.title));
    },
    currentStep() {
      return this.steps[this.currentStepIdx];
    },
    discount() {
      return this.shoppingCart.discount;
    },
    rewardsKey() {
      return (this.project || {}).crowdfunding ? 'rewards' : 'products';
    },
  },
  methods: {
    gotoProject() {
      this.$router.push({
        name: 'project',
        params: { id: this.project.vanity_url || this.project.id },
      }, () => { this.$router.go(0) });
    },
    goBack() {
      if(!this.showSucess && this.currentStepIdx > 0) {
        this.currentStepIdx -= 1;
      } else {
        this.gotoProject();
      }
    },
    goNext() {
      if(this.currentStepIdx < (this.steps.length - 1)) {
        this.currentStepIdx += 1;
      } else {
        this.showSucess = true;
      }
    },
    goTo(stepIdx) {
      if(stepIdx >= 0 && stepIdx < this.steps.length) {
        this.currentStepIdx = stepIdx;
      }
    },
    onStep(index) {
      if(index < this.currentStepIdx) {
        this.goTo(index);
      }
    },
    onSubmit(data) {
      const { currentStepIdx } = this;
      if(currentStepIdx === this.stepTypes.SHIPPING) {
        this.shippingAddress = data;
      }
      this.goNext();
    },
  },
  async mounted() {
    try {
      this.project = await getProjectDetail(this.$route.params.id);
      await this.$store.dispatch(GET_CART, this.project.id);
    } catch(e) {
      console.log(e);
    }
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.checkout-modal {
  padding-bottom: 0;
  overflow-x: hidden;

  .btn-back {
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      margin-bottom: 4px;
    }
    span {
      @include subheading;
      margin-left: 8px;
    }
  }

  .expanded-header {
    padding-left: 60px;
    padding-right: 60px;
  }

  .modal-inner {
    max-width: 100%;
    text-align: left;
  }

  .checkout {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    h5 {
      margin-bottom: 8px;
    }

    .p-input-wrap, .p-select-wrap {
      margin-top: 0;
    }

    .checkout-form,
    .checkout-items,
    .checkout-note {
      width: 100%;
    }

    .checkout-right {
      display: flex;
      flex-direction: column;
      padding-left: 60px;
    }

    .checkout-note {
      @include p_small;
      background-color: $section-highlight;
      max-width: 620px;
      padding: 24px 32px 24px;
      .checkout-note-title {
        font-family: $font-title;
        font-weight: 700;
      }
      > div {
        padding-right: 8px;
      }
    }

    .checkout-items {
      border: 1px solid $border-light;
      height: 100%;
      min-width: 560px;
      margin-top: 16px;
      padding: 24px 32px 16px;
    }

    .checkout-form {
      h2 {
        margin-bottom: 8px;
      }
      .p-stepper {
        margin: 8px 0 32px;
      }
      .p-stepper-prev {
        cursor: pointer;
      }
      .btn-submit {
        @include button($main-blue, full);
        margin-top: 16px;
      }
    }
    .safe-checkout {
      display: flex;
      align-items: center;
      margin: 24px auto 0;
      .safe-ssl {
        height: 81px;
      }
      .safe-wrap {
        margin-left: 16px;
        .safe-text-wrap {
          display: flex;
          align-items: center;
          .safe-line {
            height: 1px;
            background-color: black;
            flex-grow: 2;
          }
          .safe-text {
            @include h5;
            margin: 2px 16px 0 8px;
          }
          .safe-lock {
            height: 12px;
            margin-left: 16px;
          }
        }
        .safe-cards {
          height: 53px;
          margin-top: 16px;
        }
      }
    }
  }

  @media (max-width: 1140px) {
    .checkout {
      flex-direction: column-reverse;
      .checkout-note {
        display: none;
      }
      .checkout-items {
        margin-top: unset;
        margin-left: unset;
        border: none;
        padding: unset;
        order: -1;
        margin-bottom: 24px;
        min-width: unset;

        h3 {
          @include h2;
        }
      }
      .checkout-right {
        padding-left: 0;
        .safe-checkout {
          display: flex;
          align-items: center;
          margin: 0 auto 32px;
          .safe-ssl {
            height: auto;
            width: 25%;
            max-width: 100px;
          }
          .safe-wrap {
            margin-left: 16px;
            width: 75%;
            .safe-text-wrap {
              .safe-lock {
                height: 12px;
                margin-left: 16px;
              }
            }
            .safe-cards {
              height: auto;
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media (max-width: $tablet-width) {
    .expanded-header {
      padding: 20px 20px 0 20px;
    }
    .modal-inner {
      padding: 0 20px 20px;
    }
  }

  @media (max-width: $mobile-width) {
    .checkout.container {
      padding: 0;
      .checkout-right {
        padding-left: 0;
      }
    }
  }
}
</style>
