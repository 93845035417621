import { CART_VARIANT_ADJUST } from '@/store/actions';

export default {
  props: {
    project: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    projectId() {
      return this.project.id;
    },
    cartTotal() {
      return this.shoppingCart.total;
    },
    cartSubtotal() {
      return this.shoppingCart.subtotal;
    },
    shippingCost() {
      return this.shoppingCart.shippingTotal;
    },
    needsShipping() {
      return this.shoppingCart.needsShipping;
    },
    discount() {
      return this.shoppingCart.discount;
    },
    shoppingCart() {
      return this.$store.getters.shoppingCart(this.projectId);
    },
    hasShoppingCart() {
      return this.$store.getters.hasShoppingCart(this.projectId);
    },
  },
  methods: {
    onVariantRemove(variant) {
      const { projectId } = this;
      this.$store.dispatch(CART_VARIANT_ADJUST, { projectId, variantId: variant.id, newQuantity: 0 });
    },
    onIncrease(variant) {
      const { projectId } = this;
      const newQuantity = this.shoppingCart.products[variant.id].quantity + 1;
      this.$store.dispatch(CART_VARIANT_ADJUST, { projectId, variantId: variant.id, newQuantity });
    },
    onDecrease(variant) {
      const { projectId } = this;
      const newQuantity = this.shoppingCart.products[variant.id].quantity - 1;
      this.$store.dispatch(CART_VARIANT_ADJUST, { projectId, variantId: variant.id, newQuantity });
    },
  },
};
