<template>
<div v-if="shoppingCart" class="pledge-items">
  <div
    v-for="product in shoppingCart.products"
    :key="product.id"
    class="pledge-items-list"
  >
    <PledgeItem
      :product="product"
      @increase-product="onIncrease"
      @decrease-product="onDecrease"
      @remove-product="onVariantRemove"
    />
  </div>
  <PledgeBilling
    :needsShipping="needsShipping"
    :shippingCost="shippingCost"
    :discount="discount"
    :fromWallet="fromWallet"
    :cartSubtotal="cartSubtotal"
    :currency="project.currency"
  />
  <div v-if="true || showCoupon" class="pledge-coupon">
    <TitledInput
      v-model="couponCode"
      name="coupon"
      :placeholder="$t('checkout.coupon')"
      :maxLength="16"
      :errorMessage="couponError"
      @input="couponError = null"
    />
    <PButton
      :animate="couponAnimate"
      @click="applyCoupon"
    >
      {{ $t('checkout.apply') }}
    </PButton>
  </div>
</div>
</template>

<script>
import PledgeItem from '@/components/pledge/PledgeItem.vue';
import PledgeBilling from '@/components/pledge/PledgeBilling.vue';
import PButton from '@/components/widget/PButton';
import TitledInput from '@/components/widget/TitledInput.vue';
import CartUpdate from '@/mixins/CartUpdate';
import { fieldErrorKey } from '@/utils/apiError';
import { APPLY_COUPON } from '@/store/actions';

export default {
  name: 'pledge-items',
  components: {
    PledgeItem,
    PledgeBilling,
    PButton,
    TitledInput,
  },
  mixins: [CartUpdate],
  props: {
    fromWallet: {
      type: Number,
      default: 0,
    },
    showCoupon: Boolean,
  },
  data() {
    return {
      couponCode: '',
      couponAnimate: false,
      couponError: null,
    };
  },
  methods: {
    async applyCoupon() {
      const { couponCode } = this;
      const projectId = this.project.id;
      if(couponCode.length < 4) {
        this.couponError = this.$t('errors.INVALID_VALUE.code');
        return;
      }
      this.couponError = null;
      this.couponAnimate = true;
      try {
        await this.$store.dispatch(APPLY_COUPON, { projectId, couponCode });
      } catch(error) {
        this.couponError = this.$t(fieldErrorKey(error.errors));
      }
      this.couponAnimate = false;
    },
  },
};
</script>

<style lang="scss">
.pledge-items {
  width: 100%;
  padding: 16px 0 0;

  .pledge-coupon {
    margin-top: 16px;
    display: flex;
    .p-input-wrap {
      margin-left: auto;
      width: 200px;
      input {
        margin-right: 16px;
      }
    }
    .p-button {
      width: 130px;
    }
  }
}
</style>
