<template>
<div class="container">
  <div v-if="project" class="checkout-success">
    <div class="pledge-header">
      <h1>{{ $t('checkout.success.congrats') }}</h1>
      <h3>{{ $t('checkout.success.just_pledged') }}</h3>
      <div class="animation">
        <!-- TODO: Split the image into pieces of icons and add animation -->
        <img :src="require('@/static/img/art/pledge_success.png')">
      </div>
    </div>
    <div class="pledge-info">
      <h3 class="item-title">
        {{ project.name }}
      </h3>
      <div class="item-image" :style="{ backgroundImage: `url(${imageUrl})`}" />
    </div>
    <div class="next-tip">
      <div class="tip-content">
        <h4>{{ $t('checkout.success.whats_next') }}</h4>
        <p>{{ $t('checkout.success.whats_next_desc') }}</p>
      </div>
      <div class="tip-content">
        <h4>{{ $t('checkout.success.voting') }}</h4>
        <p>{{ $t('checkout.success.voting_desc') }}</p>
      </div>
      <div class="tip-content social-share">
        <a :href="facebookLink" target="_blank">
          <button class="btn-social fb-share">
            <span>{{ $t('checkout.buttons.share_facebook') }}</span>
            <img class="social-icon" :src="require('@/static/img/icons/ic_facebook.png')">
          </button>
        </a>
        <a :href="twitterLink" target="_blank">
          <button class="btn-social twitter-share">
            <span>{{ $t('checkout.buttons.share_twitter') }}</span>
            <img class="social-icon" :src="require('@/static/img/icons/ic_twitter_white.svg')">
          </button>
        </a>
      </div>
    </div>
    <button class="btn-done" @click="$emit('done')">
      {{ $t('checkout.buttons.done') }}
    </button>
  </div>
</div>
</template>

<script>
import { getProjectDetail } from '@/utils/api';
import { facebookShareUrl, twitterShareUrl } from '@/utils/config';

export default {
  name: 'checkout-success',
  data() {
    return {
      project: undefined,
      facebookShareUrl,
      twitterShareUrl,
    };
  },
  computed: {
    link() {
      return `${window.location.origin}/project/${this.project.id}`;
    },
    caption() {
      return 'Join me in supporting our frontline healthcare workers!';
    },
    hash() {
      return 'PledgeToProtect';
    },
    facebookLink() {
      return `${facebookShareUrl}${this.link}&display=popup&hashtag=%23${this.hash}&quote=${this.caption}`;
    },
    twitterLink() {
      return `${twitterShareUrl}?text=${this.caption}&hashtags=${this.hash}&url=${this.link}`;
    },
    imageUrl() {
      if((this.project.images || []).length > 0) {
        return this.project.images[0].url;
      }
      if(this.project.main_image) {
        return this.project.main_image.url;
      }
      return null;
    },
  },
  methods: {
    async getProjectData() {
      this.project = await getProjectDetail(this.$route.params.id);
    },
  },
  mounted() {
    this.getProjectData();
  },
};
</script>

<style lang="scss">
@import 'widgets';

.checkout-success {
  max-width: 530px;
  margin: 56px auto;
  .pledge-header {
    padding: 48px 0;
    text-align: center;
    position: relative;
    h1 {
      margin-bottom: 16px;
    }
    .animation {
      position: absolute;
      left: 60px;
      top: -5px;
      img {
        width: 420px;
        height: 200px;
      }
    }
  }
  .pledge-info {
    margin-bottom: 40px;
    .item-image {
      height: 300px;
      background-color: #aaa;
      background-position: center;
      background-size: cover;
    }
  }
  h2, h3, .tip-content {
    margin-bottom: 40px;
  }
  .social-share {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .btn-social {
    width: 250px;
    min-width: 250px;
    display: flex;
    margin-top: 16px;
    &.fb-share {
      @include button($facebook-blue, large);
      padding: 0 16px;
    }
    &.twitter-share {
      @include button($twitter-blue, large);
      padding: 0 16px;
    }
    .social-icon {
      width: 25px;
      margin-left: auto;
    }
  }
  .btn-done {
    @include button($main-black, full);
    margin-top: 48px;
  }
}
@media (max-width: $mobile-width) {
  .pledge-header h1 {
    font-size: 32px;
  }
  .social-share a {
    width: 100%;
    .btn-social {
      width: 100%;
    }
  }
}
</style>
