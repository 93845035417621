<template>
<form class="shipping-info" @submit="onContinue">
  <TitledSelect
    v-if="shippingAddressInProfile.length"
    v-model="selectedAddress"
    :options="addressOptions"
    label="abbreviation"
    :title="$t('checkout.address.saved_addresses')"
    :placeholder="$t('shipping_address')"
    @input="onSelectAddress"
  />
  <div class="info-section">
    <h5>{{ $t('shipping_address') }}</h5>
    <AddressForm
      ref="addressForm"
      v-model="inputAddress"
      formName="address"
      :showPhone="true"
      @validate="canProceed = $event"
      @input="updateCountryCode"
    />
    <Checkbox
      :item="{ label: $t('checkout.address.save_address'), checked: saveForLater }"
      :onCheck="toggleSaveForLater"
    />
    <ErrorMessage :errorMessage="shippingError" />
    <button :animate="saving" class="btn-submit" :disabled="!canProceed || disableContinue">
      {{ $t('checkout.buttons.continue') }}
    </button>
  </div>
</form>
</template>

<script>
import AddressForm from '@/components/forms/AddressForm.vue';
import Checkbox from '@/components/widget/Checkbox.vue';
import ErrorMessage from '@/components/widget/ErrorMessage.vue';
import TitledSelect from '@/components/widget/TitledSelect.vue';
import { createShippingAddress, updateShippingAddress, pledgeUpdateShipping } from '@/utils/api';
import { fieldErrorKey } from '@/utils/apiError';
import { TOGGLE_SAVE_ADDRESS, USER_REQUEST } from '@/store/actions';

const addAbbreviation = a => ({ ...a, abbreviation: `${a.line1}, ${a.postal_code}` });

const emptyAddress = {
  phone_number: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal_code: '',
  country_code: 'USA',
};

const prepareShippingData = data => ({
  city: data.city || '',
  country_code: data.country_code || '',
  line1: data.line1 || '',
  line2: data.line2 || '',
  phone_number: data.phone_number || '',
  postal_code: data.postal_code || '',
  state: data.state || '',
});

export default {
  name: 'shipping-info',
  components: {
    Checkbox,
    TitledSelect,
    AddressForm,
    ErrorMessage,
  },
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    project: {
      type: Object,
      default: () => {},
    },
    disableContinue: Boolean,
  },
  data() {
    return {
      selectedAddress: null,
      addressOptions: [],
      inputAddress: { ...emptyAddress },
      canProceed: false,
      saving: false,
      shippingError: null,
    };
  },
  computed: {
    saveForLater() {
      return this.$store.getters.saveAddressStatus;
    },
    isProfileLoaded() {
      return this.$store.getters.isProfileLoaded;
    },
    shippingAddressInProfile() {
      return this.$store.getters.profile.shipping_addresses || [];
    },
    isAddressUpdated() {
      // Check if any saved addresses exist
      if(!this.selectedAddress) {
        return false;
      }
      // If an address is selected, check if any fields are updated
      return ['line1', 'line2', 'city', 'country_code', 'phone_number', 'state']
        .reduce((acc, field) => (
          acc || (this.inputAddress[field] !== this.selectedAddress[field])
        ), false);
    },
    isNewAddress() {
      return !this.selectedAddress || !this.selectedAddress.id;
    },
  },
  methods: {
    toggleSaveForLater() {
      this.$store.dispatch(TOGGLE_SAVE_ADDRESS);
    },
    onSelectAddress(address) {
      // Auto fill the form for display
      this.inputAddress = this.isNewAddress ? { ...emptyAddress } : { ...address };
      this.$nextTick(() => this.$refs.addressForm.validateAll());
    },
    async onContinue(e) {
      e.preventDefault();
      this.saving = true;
      try {
        const { id, ...requestData } = this.inputAddress;
        const shippingData = prepareShippingData(requestData);

        if(this.saveForLater && this.isNewAddress) {
          await createShippingAddress(shippingData);
          this.$store.dispatch(USER_REQUEST, true);
        } else if(this.saveForLater && this.isAddressUpdated) {
          await updateShippingAddress(id, shippingData);
          this.$store.dispatch(USER_REQUEST, true);
        }
        await pledgeUpdateShipping(this.project.id, shippingData);

        this.onSubmit(this.inputAddress);
      } catch(err) {
        if(err.errors) {
          this.shippingError = this.$t(fieldErrorKey(err.errors));
        }
      }
      this.saving = false;
    },
    updateCountryCode(event) {
      this.$emit('country-code', event.country_code);
    },
  },
  mounted() {
    if(this.shippingAddressInProfile.length) {
      this.addressOptions = this.shippingAddressInProfile.map(addAbbreviation);
      this.addressOptions.push({ abbreviation: this.$t('checkout.address.add_new_address') });
      this.inputAddress = { ...this.shippingAddressInProfile[0] };
      this.selectedAddress = { ...this.addressOptions[0] };
      this.$nextTick(() => this.$refs.addressForm.validateAll());
    }
    this.$emit('country-code', this.inputAddress.country_code);
  },
};
</script>

<style lang="scss">
.shipping-info {
  .info-section {
    width: 100%;
    margin-top: 8px;
  }
}
</style>
